import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';

(() => {
  const splideSelector = '[data-element="customer-marquee"]';
  const splideElements: NodeListOf<HTMLElement> = document.querySelectorAll(splideSelector);

  if (!splideElements.length) {
    return;
  }

  const splideOptions = {
    type: 'loop',
    pagination: false,
    arrows: false,
    autoWidth: true,
    gap: '24px',
    autoScroll: {
      speed: 1,
      pauseOnFocus: false,
      pauseOnHover: false,
    },
  };

  splideElements.forEach(splideElement => {
    new Splide(splideElement, splideOptions).mount({ AutoScroll });
  });
})();
